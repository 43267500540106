import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTypedDispatch } from '../../../redux/store';
import { useFindProfileDataQuery } from '../../../redux/features/auth/auth.apiSlice';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { findProfileData } from '../../../store_deprecated/actions/authActions';
import MButton from '../../../shared/MButton';
import COLORS from '../../../constants/colors';
import { locations } from '../../../routes/locations';

const NafazVerification: React.FC = () => {
  const { t, i18n } = useTranslation('verification', { useSuspense: true });
  const { data, isLoading } = useFindProfileDataQuery();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(authSelector);
  const isEnglish = i18n.language === 'en';

  useEffect(() => {
    if (user?.id) {
      dispatch(findProfileData());
    }
  }, [user?.id]);

  const handleConfirmVerification = () => {
    if (data?.nationalId && data?.birthDate) navigate(locations.nafazData());
  };

  const nationalId = Number(data?.nationalId)
    .toLocaleString(i18n.language === 'ar' ? 'ar-SA' : 'en-US')
    .replace(i18n.language === 'ar' ? /٬/g : /,/g, '');

  const DOB = moment(data?.idExpiryDate)
    .locale(i18n.language)
    .format('DD-MMM-YYYY');

  return (
    <Box
      sx={{
        mt: '-20px',
        '@media (max-width: 768px)': {
          padding: '24px',
          mt: '0px',
        },
      }}
    >
      <Box
        sx={{
          px: '100px',
          '@media (max-width: 768px)': {
            px: '0px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            '@media (max-width:768px)': {
              display: 'none',
            },
          }}
        >
          <Box>
            <Typography
              sx={{
                color: COLORS?.LIGHT_GRAY,
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              {t('completeProfile')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <ChevronRight />
          </Box>
          <Box>
            <Typography
              sx={{
                color: COLORS?.DARK_GREY,
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              {t('nafazData')}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            pt: '47px',
            '@media (max-width:768px)': {
              pt: '0px',
            },
          }}
        >
          <Typography
            sx={{
              color: '#009D8F',
              textAlign: 'center',
              fontSize: '24px',
              fontWeight: 600,
              '@media (max-width:768px)': {
                fontSize: '19px',
              },
            }}
          >
            {t('verifiedAccountDesc')}
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            py: '32px',
            '@media (max-width:768px)': {
              py: '20px',
            },
          }}
        >
          <img src="/images/nafaz-verification-image.svg" alt="" />
        </Box>
        <Box>
          <Typography
            sx={{
              color: COLORS?.BLACK,
              textAlign: 'center',
              fontSize: '24px',
              fontWeight: 600,

              '@media (max-width:768px)': {
                fontSize: '20px',
              },
            }}
          >
            {t('hi')}, {isEnglish ? data?.fullNameEn : data?.fullNameAr}
          </Typography>
        </Box>
        <Box
          sx={{
            pt: '8px',
          }}
        >
          <Typography
            sx={{
              color: COLORS?.BLACK,
              textAlign: 'center',
              fontSize: '20px',
              lineHeight: '27px',
              fontWeight: 500,

              '@media (max-width:768px)': {
                fontSize: '16px',
                lineHeight: '20px',
              },
            }}
          >
            {t('verifyDescription')}
            <br />
            {t('confirmInfo')}
          </Typography>
        </Box>
        <Box
          sx={{
            pt: '32px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '580px',
            margin: 'auto',

            '@media (max-width:768px)': {
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '16px',
              width: '100%',
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: COLORS?.WHITE,
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '16px',
              gap: '8px',
              '@media (max-width:768px)': {
                width: '100%',
              },
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                {t('nationalId')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <img src="/images/profile.svg" alt="" width={24} height={24} />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: '19px',
                  fontWeight: 600,
                }}
              >
                {nationalId}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: COLORS?.WHITE,
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '16px',
              gap: '8px',
              '@media (max-width:768px)': {
                width: '100%',
              },
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                {t('dateOfBirth')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                cursor: 'pointer',
              }}
            >
              <img src="/images/calendar.svg" alt="" width={24} height={24} />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: '19px',
                  fontWeight: 600,
                }}
              >
                {DOB}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            pt: '32px',
            width: '400px',
            margin: 'auto',
            '@media (max-width:768px)': {
              width: '100%',
            },
          }}
        >
          <MButton
            variant="contained"
            id="EmailUsButton"
            buttonProps={{
              sx: {
                backgroundColor: COLORS?.X_DARK_BLUE,
              },
            }}
            typographyProps={{
              variant: i18n.language === 'en' ? 'h6' : 'cairoH4',
              textAlign: 'center',
              color: 'white',
            }}
            onClick={handleConfirmVerification}
          >
            {t('confirmButton')}
          </MButton>
        </Box>
      </Box>
    </Box>
  );
};

export default NafazVerification;
